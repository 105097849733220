<template>
  <div signup>
    <div class="wrapper">
      <div class="inner">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Signup',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[signup] { .bg('@{img}/signup/bg_signup_mb.jpg', center, center); .cover;
  .wrapper { .p(56, 0); .hf; .flex; .justify-end;
    .inner { .max-w(335); .w(100%); .p(24, 13, 24); .bgc(#fff); .br(20); .mh-c;
      h2 { .tc; .fs(24, 24); .mb(4); .chakra; }
      h3 { .tc; .c(@c-title-black); .fs(14, 14); .mb(12); }
    }
  }
  @media (@tp-up) {
    .wrapper {
      .inner { .max-w(480); .p(78, 32);
        h2 { .fs(32, 32); .mb(6); }
      }
    }
  }
  @media (@tl-up) {
    .bg('@{img}/signup/bg_signup_pc.jpg', center, center);
    .wrapper {
      .inner { .ml(0); .mr(40); }
    }
  }
  @media (@ds-up) {
    .wrapper { .w(@wrapper-ds); .mh-c;
      .inner { .mr(0); }
    }
  }
  @media (@dm-up) {
    .wrapper { .w(@wrapper-dm); }
  }
}

</style>
